import React, { PureComponent } from 'react'
import {
  ButterCMSComponent, getButterPage,
} from '../butter'
import App from '../components/App/App'

interface Props {
  components: ButterCMSComponent[];
  title: string;
  description?: string;
  keywords?: string;
}

export default class Index extends PureComponent<Props> {
  static async getInitialProps() {
    const data = await getButterPage('home', {})
    return { ...data }
  }

  render() {
    const {
      components,
      description,
      keywords,
      title,
    } = this.props

    return (
      <App components={components} description={description} keywords={keywords} title={title}/>
    )
  }
}
